jQuery(document).ready(($) => {
  /* IE popup
  ========================================================================== */

  checkIfIE();

  function checkIfIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      alert(
        'For an optimal viewing experience, please choose a different browser.'
      );
    }

    return false;
  }

  /* Set VW without horizontal scrollbar
  ========================================================================== */

  setVw();
  function setVw() {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty('--vw', ''.concat(vw, 'px'));
  }

  window.addEventListener('resize', (params) => {
    setVw();
  });

  /* GSAP
  ========================================================================== */

  gsap.registerPlugin(ScrollTrigger);

  if ($('.fade-up').length) {
    gsap.utils.toArray('.fade-up').forEach((item, i) => {
      gsap.from(item, {
        scrollTrigger: {
          trigger: item,
          start: 'center bottom',
        },
        y: '+=150',
        opacity: 0,
      });
    });
  }

  if ($('.home-header').length) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.home-header',
        start: 'center bottom',
      },
      defaults: {
        y: '+=150',
        opacity: 0,
        duration: 0.6,
      },
    });

    tl.from('.home-header__site-title', {})
      .from('.home-header__tagline', {}, '<75%')
      .from('.home-header__hr', {}, '<75%');
  }

  if ($('.infographic-brief').length) {
    gsap.utils.toArray('.infographic-brief__part--top').forEach((part, i) => {
      gsap.from(part, {
        scrollTrigger: {
          trigger: part,
          start: 'center bottom',
        },
        y: '+=150',
        opacity: 0,
      });
    });

    gsap.set('.infographic-brief__part--bottom', { y: '+=150', opacity: 0 });

    const tl = gsap.timeline({
      delay: 0.2,
      scrollTrigger: {
        trigger: '#Part_3-1',
        start: 'top bottom',
      },
      onComplete: () => {
        gsap.utils
          .toArray('.infographic-brief__part--bottom')
          .forEach((part, i) => {
            gsap.to(part, {
              scrollTrigger: {
                trigger: part,
                start: 'center bottom',
              },
              y: '-=150',
              opacity: 100,
            });
          });
      },
    });

    tl.from('#Part_3-1', {
      y: '+=150',
      opacity: 0,
    })
      .from('#Part_3-2', {
        x: '+=150',
        opacity: 0,
      })
      .from('#Part_3-3', {
        y: '+=150',
        opacity: 0,
      })
      .from('#Part_3-4', {
        x: '-=150',
        opacity: 0,
      });
  }

  if ($('.latest-updates').length) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.latest-updates',
        start: 'bottom bottom',
      },
      defaults: {
        y: '+=150',
        opacity: 0,
        duration: 0.6,
      },
    });

    tl.from('.latest-updates__updates>li:nth-child(1)', {})
      .from('.latest-updates__updates>li:nth-child(2)', {}, '<50%')
      .from('.latest-updates__updates>li:nth-child(3)', {}, '<50%');
  }

  if ($('.about-data-list').length) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.about-data-list',
        start: 'center bottom-=200px',
      },
      defaults: {
        y: '+=150',
        opacity: 0,
        duration: 0.6,
      },
    });

    tl.from('.about-data-list>li:nth-child(1)', {}).from(
      '.about-data-list>li:nth-child(2)',
      {},
      '<50%'
    );
  }

  /* Single data view more
  ========================================================================== */

  /*   $(".metadata__view-more-btn").on("click", function () {
    const viewMorePromise = new Promise((resolve, reject) => {
      $(".metadata__row--more").fadeToggle(400);

      $(this).text() === "View More"
        ? $(this)
            .fadeOut(200, () => {
              $(this).text("View Less");
            })
            .fadeIn(200)
        : $(this)
            .fadeOut(200, () => {
              $(this).text("View More");
            })
            .fadeIn(200);

      setTimeout(() => {
        resolve();
      }, 400);
    }).then(() => {
      window.scrollTo({
        top: $(".metadata").offset().top - 80,
        behavior: "smooth",
      });
    });
  }); */

  /* Data groups view more
  ========================================================================== */

  $('.metadata-by-groups__view-more-btn').on('click', function () {
    $(this).next().children('.metadata-by-groups__metadata').fadeToggle(400);

    $(this).text() === 'View More'
      ? $(this)
          .fadeOut(200, () => {
            $(this).text('View Less');
          })
          .fadeIn(200)
      : $(this)
          .fadeOut(200, () => {
            $(this).text('View More');
          })
          .fadeIn(200);
  });

  /* Update the institutional affiliation field in Account tab with its content 
    to fix value = 0, 1, etc. issue
  ========================================================================== */

  $('.um-page-account #institutional_affiliation > option').each(function (
    index,
    element
  ) {
    // element == this
    $(this).val($(this).text());
  });
});
